import React from "react"

const ContactBlock = () => {
  return (
    <div className="block" id="contact">
      <h2>Contact</h2>
      <div className="block-sub">
        <div className="p-block">
          <p>
            <span>Phone:</span> (202) 974-5142
          </p>

          <p style={{ display: "flex", gap: "10px" }}>
            <span>Email: </span>
            <a href="mailto:andreaferster@gmail.com">
              {" "}
              andreaferster@gmail.com
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default ContactBlock
