import React from "react"
import Layout from "../components/Layout"
import ContactBlock from "../components/contactBlock"

const Contact = () => {
  return (
    <Layout>
      <div className="page">
        <div className="min-hero-block">
          <div className="sub">
            <h1>Contact</h1>
          </div>
        </div>

        <div className="main-content">
          {/* Contact */}
          <ContactBlock />
        </div>
      </div>
    </Layout>
  )
}

export default Contact
